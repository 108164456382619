import { InfoIcon } from 'lucide-react'
import { useRouter } from 'next/router'
import { ComponentType, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { TextButton } from '~/core/ui/TextButton'
import useBoundStore from '~/lib/store'
import { TopSpaceProvider } from './TopSpace'
import useSubscriptionPlan from './useSubscriptionPlan'

const TrialBanner = ({ name }: { name?: string } = {}) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const router = useRouter()
  if (user.ownTenant) {
    return (
      <div className="flex items-center bg-blue-100 px-6 py-2">
        <InfoIcon size={16} color="#5081F0" />
        <div className="ml-2 flex flex-row items-center text-xs text-gray-600">
          {t('settings:plan:reachLimitedPlan', { name })}
          <TextButton
            underline={false}
            className="px-1"
            onClick={() => router.push(configuration.path.settings.plans)}
            size="sm"
            type="primary">
            {t('settings:plan:reachLimitedPlanButton')}
          </TextButton>
          {t('settings:plan:reachLimitedPlan01')}
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center bg-blue-100 px-6 py-2">
      <InfoIcon size={16} color="#5081F0" />
      <div className="ml-2 text-xs text-gray-600">
        {t('settings:plan:reachLimitedPlanUpgrade', { name })}
      </div>
    </div>
  )
}

export const withTrialBanner = <T extends object>(
  Component: ComponentType<T>
) => {
  const WithTrialBanner = (props: T) => {
    const { isLimitReached, data, planName, expiredError } =
      useSubscriptionPlan()
    const isShowTrialBanner =
      isLimitReached && (!data || !data?.isExpired) && !expiredError
    return (
      <TopSpaceProvider value={{ top: isShowTrialBanner ? '34' : undefined }}>
        {isShowTrialBanner && (
          <div className="fixed z-20 h-8 w-[100%]">
            <TrialBanner name={planName || ''} />
          </div>
        )}
        <Component
          {...(props as T)}
          className={isShowTrialBanner && 'h-[calc(100vh_-_34px)] pt-[34px]'}
          sidebarClassName={isShowTrialBanner && 'h-[calc(100vh_-_34px)]'}
        />
      </TopSpaceProvider>
    )
  }
  return WithTrialBanner
}
export const TopTrialBanner = (props: PropsWithChildren) => {
  return (
    <>
      <div className="fixed h-8 w-[100%]">
        <TrialBanner />
      </div>
      {props.children}
    </>
  )
}

export default TrialBanner
