'use client'

import { cva } from 'class-variance-authority'
import { forwardRef, ReactNode } from 'react'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import { Tooltip } from '~/core/ui/Tooltip'

export const textButtonRootVariants = cva('flex items-center justify-center', {
  variants: {
    variant: {
      primaryWithDisabled:
        'pointer-events-none text-primary-200 dark:text-primary-800',
      secondaryTertiaryWithDisabled:
        'pointer-events-none text-gray-400 dark:text-gray-600',
      destructiveWithDisabled:
        'pointer-events-none text-red-300 dark:text-red-700',
      primaryNoDisabled:
        'text-primary-400 hover:text-primary-600 focus:text-primary-600 dark:hover:text-primary-300 dark:focus:text-primary-300',
      secondaryNoDisabled:
        'text-gray-600 [&>svg]:text-gray-500 hover:!text-primary-600 [&>svg]:hover:text-primary-600 focus:text-primary-600 [&>svg]:focus:text-primary-600 dark:text-gray-300 dark:hover:text-primary-300 [&>svg]:dark:hover:text-primary-300 dark:focus:text-primary-300 [&>svg]:dark:focus:text-primary-300',
      tertiaryNoDisabled:
        'text-gray-500 [&>svg]:text-gray-400 hover:text-primary-600 [&>svg]:hover:text-primary-600 focus:text-primary-600 [&>svg]:focus:text-primary-600 dark:text-gray-400 dark:hover:text-primary-300 [&>svg]:dark:hover:text-primary-300 dark:focus:text-primary-300 [&>svg]:dark:focus:text-primary-300',
      secondaryDestructiveNoDisabled:
        'text-gray-600 hover:text-red-500 focus:text-red-500 dark:text-gray-300',
      tertiaryDestructiveNoDisabled:
        'text-gray-500 hover:text-red-500 focus:text-red-500 dark:text-gray-400',
      destructiveNoDisabled:
        'text-red-500 hover:text-red-600 focus:text-red-600 dark:hover:text-red-400 dark:focus:text-red-400'
    },
    icon: {
      leading: 'flex-row',
      trailing: 'flex-row-reverse'
    },
    underline: {
      underline: 'hover:underline focus:underline',
      default: ''
    }
  },
  defaultVariants: {
    variant: 'primaryNoDisabled',
    icon: 'leading',
    underline: 'underline'
  }
})

const textButtonLabelVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xs',
      md: 'text-sm',
      lg: 'text-base'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

const textButtonIconVariants = cva('', {
  variants: {
    size: {
      4: 'mr-1.5',
      3: 'mr-1',
      2: 'ml-1.5',
      1: 'ml-1'
    }
  },
  defaultVariants: {
    size: 4
  }
})

const getTextButtonSizeIcon = {
  lg: 18,
  md: 16,
  sm: 14
}

const getTextButtonSpaceCondition = ({
  size = 'md',
  icon
}: {
  size?: 'sm' | 'md' | 'lg'
  icon?: 'leading' | 'trailing'
}) => {
  if (['lg', 'md'].includes(size) && icon === 'leading') return 4
  if (size === 'sm' && icon === 'leading') return 3
  if (['lg', 'md'].includes(size) && icon === 'trailing') return 2
  if (size === 'sm' && icon === 'trailing') return 1

  return 4
}

export const getTextButtonVariantColorCondition = ({
  type = 'primary',
  isDisabled = false
}: {
  type?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'secondary-destructive'
    | 'tertiary-destructive'
    | 'destructive'
  isDisabled?: boolean
}) => {
  if (isDisabled == true && type === 'primary') return 'primaryWithDisabled'
  if (
    isDisabled == true &&
    ['secondary', 'tertiary', 'secondary-destructive'].includes(type)
  )
    return 'secondaryTertiaryWithDisabled'
  if (isDisabled == true && type === 'destructive')
    return 'destructiveWithDisabled'
  if (isDisabled == false && type === 'primary') return 'primaryNoDisabled'
  if (isDisabled == false && type === 'secondary') return 'secondaryNoDisabled'
  if (isDisabled == false && type === 'tertiary') return 'tertiaryNoDisabled'
  if (isDisabled == false && type === 'secondary-destructive')
    return 'secondaryDestructiveNoDisabled'
  if (isDisabled == false && type === 'tertiary-destructive')
    return 'tertiaryDestructiveNoDisabled'
  if (isDisabled == false && type === 'destructive')
    return 'destructiveNoDisabled'

  return 'primaryNoDisabled'
}

interface TextButtonProps {
  type?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'secondary-destructive'
    | 'tertiary-destructive'
    | 'destructive'
  size?: 'sm' | 'md' | 'lg'
  label?: ReactNode
  labelDescription?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled?: boolean
  icon?: 'leading' | 'trailing'
  iconMenus?: LucideIconName
  iconMenusLoading?: string
  classNameText?: string
  underline?: boolean
  children?: ReactNode
  className?: string
  dot?: IDotColorProps
  dotDescription?: string
}

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  (props, ref) => {
    const {
      label,
      labelDescription,
      type = 'primary',
      size = 'md',
      isDisabled = false,
      icon,
      onClick,
      iconMenus,
      iconMenusLoading,
      classNameText = 'font-medium',
      underline = true,
      className,
      children,
      dot,
      dotDescription
    } = props
    return (
      <button
        ref={ref}
        type="button"
        disabled={isDisabled}
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}
        className={cn(
          textButtonRootVariants({
            variant: getTextButtonVariantColorCondition({ type, isDisabled }),
            icon,
            underline: underline ? 'underline' : 'default',
            className
          })
        )}>
        {children ? (
          <div
            className={cn(
              textButtonLabelVariants({ size, className: classNameText })
            )}>
            {children}
          </div>
        ) : (
          <>
            {iconMenus && (
              <IconWrapper
                className={cn(
                  textButtonIconVariants({
                    size: getTextButtonSpaceCondition({ size, icon }),
                    className: iconMenusLoading
                  })
                )}
                size={getTextButtonSizeIcon[size]}
                name={iconMenus}
              />
            )}
            {labelDescription ? (
              <Tooltip
                isCustomArrow
                content={label}
                classNameAsChild={cn(
                  textButtonLabelVariants({ size, className: classNameText })
                )}>
                {label}
              </Tooltip>
            ) : (
              <span
                className={cn(
                  textButtonLabelVariants({ size, className: classNameText })
                )}>
                {label}
              </span>
            )}
            {dot ? (
              <>
                {dotDescription ? (
                  <Tooltip
                    isCustomArrow
                    classNameAsChild="mr-1.5 flex items-center"
                    content={dotDescription}>
                    <Dot size="xl" color={dot} />
                  </Tooltip>
                ) : (
                  <div className="mr-1.5 flex items-center">
                    <Dot size="xl" color={dot} />
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
      </button>
    )
  }
)

TextButton.displayName = 'TextButton'

export { TextButton }
export type { TextButtonProps }
